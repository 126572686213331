<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <b-modal
        id="modal-view"
        cancelTitle="Cancel"
        centered
        ok-title="Verify"

        title="Verify Review"
        @ok.prevent="verifyReview"
    >
      <div v-if="selectedGameReview" class="row">
        <div class="col-md-12" style="overflow-wrap: anywhere">
          <p>{{ selectedGameReview.comment }}</p>
        </div>
      </div>
    </b-modal>
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        title="Delete Review"
        @ok.prevent="deleteGameReview"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this Game Review ?</span>
        </div>
      </div>
    </b-modal>
    <div v-if="gameReviews">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <h1>Game Reviews</h1>

        <div class="my-2">
          <!-- Table Top -->
          <b-row>

            <b-col class="d-flex flex-column   mb-1 mb-md-0" cols="12" md="2"
            >
              <b-form-group label="Count">

                <v-select
                    v-model="perPage"
                    :clearable="false"
                    :options="perPageOptions"
                    class="per-page-selector  "
                />
              </b-form-group>
            </b-col>
            <b-col class="d-flex flex-column   mb-1 mb-md-0" cols="12" md="3"
            >
              <b-form-group label="Search">

                <Debouncer @setValue="setSearch"></Debouncer>
              </b-form-group>
            </b-col>
            <b-col class="d-flex flex-column   mb-1 mb-md-0" cols="12" md="3"
            >
              <b-form-group label="Status">

                <v-select
                    v-model="verified"
                    :options="[{name:'Verified',value:true},{name:'Not Verified',value:false}]"
                    :reduce="name=> name.value"
                    class="bg-white"
                    dir="ltr"
                    label="name"
                    placeholder="Status"
                />
              </b-form-group>
            </b-col>
            <b-col class="d-flex flex-column   mb-1 mb-md-0" cols="12" md="3"
            >
              <b-form-group label="Game">

                <v-select
                    v-if="games"
                    v-model="gameId"
                    :options="games"
                    :reduce="name=> name.id"
                    class="bg-white"
                    dir="ltr"
                    label="name"
                    placeholder="Selected Game"
                />
              </b-form-group>
            </b-col>
            <b-col
                v-if="gameServices"
                class="d-flex flex-column  mb-1 mb-md-0" cols="12" md="4"
            >
              <b-form-group label="Game Services">
                <v-select
                    v-model="gameServiceId"
                    :options="gameServices"
                    :reduce="title=> title.id"
                    class="bg-white"
                    dir="ltr"
                    label="title"
                    placeholder="select game first"
                />
              </b-form-group>
            </b-col>
          </b-row>


        </div>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="gameReviews"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            sort-by.sync
            striped
        >

          <template #cell(isTrusted)="data">
            <b-badge v-if="data.item.isTrusted" variant="success">
              Verified
            </b-badge>
            <b-badge v-else-if="!data.item.isTrusted" variant="danger">
              Not Verified
            </b-badge>
          </template>
          <template #cell(operation)="data">
            <div class="d-flex align-items-center  w-100">
              <span v-b-modal.modal-view class="cursor-pointer mx-1"
                    @click="setSelectedGameReview(data.item)"
              >

              <feather-icon
                  class="text-primary"
                  icon="EyeIcon"
                  size="20"
              />
              </span>
              <span v-b-modal.modal-delete class="cursor-pointer mx-1" @click="setSelectedGameReview(data.item)">
                            <feather-icon
                                class="text-danger"
                                icon="TrashIcon"
                                size="20"
                            />
                            </span>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import { BBadge, BCard, BCol, BFormGroup, BModal, BOverlay, BPagination, BRow, BTable } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Helper from '@/libs/Helper'
import { DeleteReview, GetAllGameReviews, VerifyReview } from '@/libs/Api/GameReviews'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import Debouncer from '@/views/components/debouncer.vue'
import { GetAllGames, GetGameServicesByGameId } from '@/libs/Api/games'

export default {
  title: 'game-reviews',
  name: 'gameReviews',
  data() {
    return {
      required,
      gameReviews: null,
      totalCount: null,
      showOverlay: false,
      currentPage: 1,
      baseURL: Helper.baseUrl + 'media/gallery/profile/',
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true

        },
        {
          key: 'username',
          label: 'name',
          sortable: true
        },
        {
          key: 'gameName',
          label: 'game Name',
          sortable: true
        },
        {
          key: 'gameServiceTitle',
          label: 'game Service Title',
          sortable: true
        },
        {
          key: 'isTrusted',
          label: 'status',
          sortable: true
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      pageNumber: 1,
      selectedGameReview: null,
      search: '',
      games: null,
      gameId: null,
      gameServices: null,
      gameServiceId: null,
      verified: null,
    }
  },
  async created() {
    await Promise.all([
      this.getGameReviews(),
      this.getGames(),

    ])
  },
  watch: {
    currentPage: function () {
      this.getGameReviews()
    },
    perPage: function () {
      this.getGameReviews()
    },
    gameId: function (val, oldVal) {
      if (val && (oldVal !== val)) {
        this.gameServiceId = null
        this.getGameServicesByGameId()
      }
      this.getGameReviews()
    },
    gameServiceId: function (val) {
      this.getGameReviews()
    },
    verified: function () {
      this.getGameReviews()
    }

  },
  methods: {
    async getGameServicesByGameId() {
      let _this = this
      _this.showOverlay = true
      let getGameServicesByGameId = new GetGameServicesByGameId(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        gameId: _this.gameId
      }
      getGameServicesByGameId.setParams(data)
      await getGameServicesByGameId.fetch(function (content) {
        _this.gameServices = content.data.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getGames() {
      let _this = this
      _this.showOverlay = true
      let getAllGames = new GetAllGames(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        search: ''
      }
      getAllGames.setParams(data)
      await getAllGames.fetch(function (content) {
        _this.games = content.data.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

    setSearch(search) {
      this.search = search
      this.getGameReviews()
    },

    setSelectedGameReview(item) {
      this.selectedGameReview = JSON.parse(JSON.stringify(item))
    },
    async getGameReviews() {
      let _this = this
      _this.showOverlay = true
      let getAllGameReviews = new GetAllGameReviews(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.search,
        gameId: _this.gameId ? _this.gameId : '',
        gameServiceId: _this.gameServiceId ? _this.gameServiceId : '',
        isTrusted: _this.verified!==null ? _this.verified : ''
      }
      getAllGameReviews.setParams(data)
      await getAllGameReviews.fetch(function (content) {
        _this.gameReviews = content.data.data
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async verifyReview() {
      let _this = this
      if (!_this.selectedGameReview.isTrusted) {
        _this.showOverlay = true
        let verifyReview = new VerifyReview(_this)
        verifyReview.setParams({ commentId: _this.selectedGameReview.id })
        await verifyReview.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `Game Review successfully Verified`,
            },
          })
          _this.getGameReviews()
          _this.$bvModal.hide('modal-view')
          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      } else {
        _this.$nextTick(() => {
          _this.$bvModal.hide('modal-view')
        })

      }
    },
    async deleteGameReview() {
      let _this = this
      _this.showOverlay = true
      let deleteGameReview = new DeleteReview(_this)
      deleteGameReview.setParams({ id: _this.selectedGameReview.id })
      await deleteGameReview.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Game Review successfully deleted`,
          },
        })
        _this.getGameReviews()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },

  },

  components: {
    Debouncer,
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BTable,
    BFormGroup,
    BBadge,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>
