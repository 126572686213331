import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllGameReviews extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('GameServiceComments/GetAllCommentsByGameId')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class VerifyReview extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('GameServiceComments/VerifyComment')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class DeleteReview extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
  }

  setParams(data) {
    super.setTag(`GameServiceComments/${data.id}`)
  }
}

export {
  GetAllGameReviews,
  VerifyReview,
  DeleteReview

}
